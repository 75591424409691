import React, {Suspense, lazy} from 'react'
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Header from './components/Header'
import routes from './routes'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const Login = lazy(() => import('./screens/Login')) 


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#282E67',
    },
    secondary: {
      main: '#ff4081',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        fontSize: '0.8rem',
      },
      footer: {
        left: 0,
        bottom: 0, // <-- KEY
        zIndex: 2,
        position: 'sticky',
        backgroundColor: '#fff',
      },
    },
  },
})

export default function App() {
  const isAuth = localStorage.getItem('productFeedAuthKey')
  return (
    <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<h1>&nbsp;</h1>}>
            {isAuth ? <Header /> : null}
              <Switch>
                <Route exact strict path="/login/" component={Login} />
                {!isAuth && <Redirect to="/login/" />}
                  {routes.map((route) => (
                    <Route key={route.title} {...route} />
                  ))}
              </Switch>
          </Suspense>
        </Router>
    </ThemeProvider>
  )
}
