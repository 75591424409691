import { useState, useEffect } from 'react';
import { makeCall } from 'utils/util';
import { Grid, Button } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { containerStyles, tableContainerStyles as useStyles } from 'elements/container';
import { ModalType } from 'types/global';
import Snackbar from 'components/Snackbar';
import { StatsType, Data, Record } from './types';
import { columns } from './initialData';
import StatsContainer from './CardsContainer';
import { Country, Currency } from 'types/global';
import Create from './Create';
import { Color } from '@material-ui/lab/Alert';
import { Data as APIKeysType } from 'screens/ApiKeys/types';
import APIKeyModal from './ApiKeyModal';

export default function Feeds() {
  const containerClasses = containerStyles();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingStats, setIsLoadingStats] = useState(true);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState<Color>('success');
  const [stats, setStats] = useState<StatsType[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const [record, setRecord] = useState<Record>(null);
  const [sources, setSources] = useState<any>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [apiKeys, setApiKeys] = useState<APIKeysType[]>([]);
  const [apiKeyFeedUuid, setApiKeyFeedUuid] = useState('');
  const [isApiKeyModal, setIsApiKeyModal] = useState(false);

  useEffect(() => {
    makeCall('feeds/stats', 'GET').then((res) => {
      setStats(res?.data.data);
      setIsLoadingStats(false);
    });
    makeCall('feeds/sources', 'GET').then((res) => {
      console.log(res?.data);
      setSources(res?.data.data);
    });
    makeCall('languages', 'GET').then((res) => {
      setLanguages(res?.data.data);
    });
    makeCall('countries', 'GET').then((res) => {
      setCountries(res?.data.data);
    });
    makeCall('currencies', 'GET').then((res) => {
      setCurrencies(res?.data.data);
    });
    makeCall('apikeys', 'GET').then((res) => {
      console.log(res);
      setApiKeys(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      makeCall('feeds', 'GET').then((res) => {
        setData(res?.data.data);
        setIsLoading(false);
        setShouldRefresh(false);
      });
    }
  }, [shouldRefresh]);

  const queueAll = async () => {
    await makeCall(`feeds/queue/all`, 'POST');
    const message = `Feeds queued successfully!`;
    setToastMessage(message);
    setToastSeverity('success');
    setToastOpen(true);
  };

  const refresh = (message: string, severity: Color) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
    if (severity === 'success') {
      setIsLoading(true);
      setShouldRefresh(true);
    }
  };

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`feeds/${clickedRow.feedUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('create');
  };

  const addToQueueSingle = async (clickedRow: Data) => {
    await makeCall(`feeds/queue`, 'POST', { feedUuids: [clickedRow.feedUuid] });
    const message = `Feed queued successfully!`;
    setToastMessage(message);
    setToastSeverity('success');
    setToastOpen(true);
  };

  const handleClose = () => {
    setModalType(null);
    if (record) {
      setRecord(null);
    }
  };

  const handleDownloadFeedUrl = (clickedRow: Data) => {
    setApiKeyFeedUuid(clickedRow.feedUuid);
    setIsApiKeyModal(true);
  };

  return (
    <>
      <Create
        open={modalType === 'create'}
        handleClose={handleClose}
        handleRefresh={refresh}
        currencies={currencies}
        countries={countries}
        languages={languages}
        sources={sources}
        record={record}
      />
      <APIKeyModal
        open={isApiKeyModal}
        handleClose={() => {
          setIsApiKeyModal(false);
          setApiKeyFeedUuid('');
        }}
        apiKeys={apiKeys}
        feedUuid={apiKeyFeedUuid}
      />
      <Snackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} severity={toastSeverity} />
      <div className={containerClasses.container}>
        <Grid container className={classes.root}>
          <StatsContainer data={stats} isLoading={isLoadingStats} />
          <Grid item xs={12} style={{ textAlign: 'right', margin: '20px 0' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalType('create')}
              style={{ marginRight: '20px' }}
            >
              Add Feed
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => refresh('Feeds refreshed successfully!', 'success')}
              style={{ marginRight: '20px' }}
            >
              Reload
            </Button>
            <Button variant="contained" color="secondary" onClick={() => queueAll()}>
              Queue All
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="Feeds"
            components={{
              Toolbar: (props) => (
                <div className={classes.toolbarWrapper}>
                  <MTableToolbar {...props} />
                </div>
              ),
            }}
            options={{
              search: true,
              pageSize: 8,
              actionsColumnIndex: 10,
              actionsCellStyle: { color: '#999' },
            }}
            actions={[
              {
                icon: 'code',
                tooltip: 'Download feed url',
                isFreeAction: false,
                onClick: (event, rowData: any) => handleDownloadFeedUrl(rowData),
              },
              {
                icon: 'edit',
                tooltip: 'Update Feed',
                isFreeAction: false,
                onClick: (event, rowData: any) => getRecord(rowData),
              },
              {
                icon: 'add_to_queue',
                tooltip: 'Add to queue',
                isFreeAction: false,
                onClick: (event, rowData: any) => addToQueueSingle(rowData),
              },
            ]}
            isLoading={isLoading}
            columns={columns}
            data={data}
          />
        </Grid>
      </div>
    </>
  );
}
