import { Column } from '@material-table/core';
import { Data, FormProps } from './types';
import { green, red } from '@material-ui/core/colors';
import { Icon } from '@material-ui/core';

const cellStyle = { fontWeight: 400, fontFamily: 'Roboto', fontSize: '0.8rem' };

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'apiKeyUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'API key name', field: 'apiKeyName', cellStyle: { ...cellStyle, width: '35%' } },
  {
    title: 'Active',
    field: 'apiKeyActiveStatus',
    render: (row) =>
      row.apiKeyActiveStatus ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  { title: 'Created on', field: 'apiKeyCreatedDatetime', cellStyle },
  { title: 'Updated on', field: 'apiKeyUpdatedDatetime', cellStyle },
];

export const createDefaultValues: FormProps = {
  apiKeyName: '',
};
