import { makeStyles } from "@material-ui/core";

export const containerStyles = makeStyles(() => ({
  container: {
    margin: '40px',
    marginTop: '20px',
  marginLeft: '340px',
  minWidth: '1280px',
  }
}))

export const tableContainerStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
  },
  toolbarWrapper: {
    '& .MuiToolbar-gutters': {
      padding: '0 20px',
    },
    '& .MuiTypography-h6': {
      color: theme.palette.primary.main,
    },
  },
}));
