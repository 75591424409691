import { Storefront, Web, RssFeed, VpnKey } from '@material-ui/icons';

export const links = [
  {
    id: 'feeds',
    text: 'Feeds',
    subNav: [],
    url: '/',
    icon: <RssFeed />,
  },
  {
    id: 'apikeys',
    text: 'API keys',
    subNav: [],
    url: '/apikeys',
    icon: <VpnKey />,
  },
  {
    id: 'countries',
    text: 'Countries',
    subNav: [],
    url: '/countries',
    icon: <Storefront />,
  },
  {
    id: 'currencies',
    text: 'Currencies',
    subNav: [],
    url: '/currencies',
    icon: <Web />,
  },
  {
    id: 'admins',
    text: 'Admins',
    subNav: [],
    url: '/admins',
    icon: <Web />,
  },
];
