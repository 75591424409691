import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Divider,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import JsonEditor from './JsonEditor'
import { makeCall } from 'utils/util';
import { FormProps as FormType, CreateModalProps as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';
import React from 'react';

export default function Create({
  open,
  handleClose,
  handleRefresh,
  countries,
  currencies,
  languages,
  sources,
  record,
}: PropsType) {
  const [group, setGroup] = useState('');
  const [groupOptions, setGroupOptions] = useState<any[]>([]);
  const [sourceKey, setSourceKey] = useState('');
  const [sourceFields, setSourceFields] = useState<any[]>([]);
  const [isSourceSelected, setIsSourceSelected] = useState(false);
  const [url, setUrl] = useState('');
  const [uuid, setUuid] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [image, setImage] = useState<{ currentFile: any; previewImage: any }>({
    currentFile: '',
    previewImage: '',
  });
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    getValues,
  } = useForm<FormType>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
    setGroup('');
    setUrl('');
    setUuid('');
    setGroupOptions([]);
    setSourceKey('');
    setSourceFields([]);
    setIsSourceSelected(false);
    setIsActive(true);
    setImage({
      currentFile: '',
      previewImage: '',
    });
    setIsUploadingImage(false);
  };

  const onSubmit = async (data: FormType) => {
    const payload = {
      ...data,
      feedSourceGroup: group,
      feedCountryCode: data.feedCountryCode?.countryCode ?? '',
      feedCurrencyCode: data.feedCurrencyCode?.currencyCode ?? '',
    };
    if (uuid) {
      const response = await makeCall(`feeds/${uuid}`, 'PUT', { ...payload, feedIsActive: isActive });
      if (response?.data.code === 200) {
        handleRefresh('Feed updated successfully!', 'success');
      } else {
        handleRefresh(response?.data.message, 'error');
      }
      return;
    }

    const response = await makeCall('feeds', 'POST', payload);
    if (response?.data.code === 200) {
      setUuid(response?.data.data);
      handleRefresh('Feed created successfully!', 'success');
    } else {
      handleRefresh(response?.data.message, 'error');
    }
  };

  const handleGroupChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setGroupOptions(sources[e.target.value]);
    reset({ feedSourceKey: '' });
    if (isSourceSelected) {
      setIsSourceSelected(false);
    }
  };

  const handleSourceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedSource = groupOptions.filter((row) => row.sourceKey === e.target.value)[0];
    setGroup(selectedSource.group);
    console.log(selectedSource);
    setSourceKey(selectedSource.sourceKey);
    setSourceFields(selectedSource.fields);
    setUrl('');
    reset({
      feedSourceGroup: getValues('feedSourceGroup'),
      feedImportId2: '',
      feedImportId1: '',
      feedSecret: '',
      feedId: '',
      feedUrl: '',
      feedLanguage: null,
      feedDomain: '',
      feedName: '',
      feedCurrencyCode: null,
      feedCountryCode: null,
      feedIsAdult: false,
    });
    if (!isSourceSelected) {
      setIsSourceSelected(true);
    }
  };

  const splitValues = () => {
    if (sourceKey === 'partnerads') {
      const urlSearchParams = new URLSearchParams(url.split('?')[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      reset({
        feedSourceGroup: getValues('feedSourceGroup'),
        feedSourceKey: getValues('feedSourceKey'),
        feedImportId2: params.partnerid ? params.partnerid : '',
        feedImportId1: params.bannerid ? params.bannerid : '',
        feedSecret: '',
        feedId: params.feedid ? params.feedid : '',
        feedUrl: '',
        feedLanguage: getValues('feedLanguage'),
        feedDomain: getValues('feedDomain'),
        feedName: getValues('feedName'),
        feedCurrencyCode: getValues('feedCurrencyCode'),
        feedCountryCode: getValues('feedCountryCode'),
        feedIsAdult: getValues('feedIsAdult'),
      });
    }

    if (sourceKey === 'tradedoubler') {
      try {
        const urlParts = new URL(url);
        const token = urlParts.search.split('token=');
        const fid = urlParts.pathname.split('fid=');
        reset({
          feedSourceGroup: getValues('feedSourceGroup'),
          feedSourceKey: getValues('feedSourceKey'),
          feedImportId2: '',
          feedImportId1: '',
          feedSecret: token.length === 2 ? token[1] : '',
          feedId: fid.length === 2 ? fid[1] : '',
          feedUrl: '',
          feedLanguage: getValues('feedLanguage'),
          feedDomain: getValues('feedDomain'),
          feedName: getValues('feedName'),
          feedCurrencyCode: getValues('feedCurrencyCode'),
          feedCountryCode: getValues('feedCountryCode'),
          feedIsAdult: getValues('feedIsAdult'),
        });
      } catch (error) {
        console.log(error);
      }
    }

    if (sourceKey === 'tradetracker') {
      const urlSearchParams = new URLSearchParams(url.split('?')[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      reset({
        feedSourceGroup: getValues('feedSourceGroup'),
        feedSourceKey: getValues('feedSourceKey'),
        feedImportId2: '',
        feedImportId1: params.aid ? params.aid : '',
        feedSecret: '',
        feedId: params.fid ? params.fid : '',
        feedUrl: '',
        feedLanguage: getValues('feedLanguage'),
        feedDomain: getValues('feedDomain'),
        feedName: getValues('feedName'),
        feedCurrencyCode: getValues('feedCurrencyCode'),
        feedCountryCode: getValues('feedCountryCode'),
        feedIsAdult: getValues('feedIsAdult'),
      });
    }
  };

  useEffect(() => {
    if (record) {
      console.log(record);
      const selectedCountry = countries.filter((row) => row.countryCode === record.feedCountryCode)[0];
      const selectedCurrency = currencies.filter((row) => row.currencyCode === record.feedCurrencyCode)[0];
      const selectedLanguage = languages.filter((row) => row === record.feedLanguage)[0];
      const selectedGroup = sources[`${record.feedSourceGroup}s`];
      const selectedSource = selectedGroup.filter((row: any) => row.sourceKey === record.feedSourceKey)[0];
      setGroup(selectedSource.group);
      setSourceKey(selectedSource.sourceKey);
      setSourceFields(selectedSource.fields);
      setGroupOptions(selectedGroup);
      setUuid(record.feedUuid);
      setIsSourceSelected(true);
      setIsActive(record.feedIsActive);
      reset({
        feedImportId2: record.feedImportId2,
        feedImportId1: record.feedImportId1,
        feedSecret: record.feedSecret,
        feedId: record.feedId,
        feedUrl: record.feedUrl,
        feedSourceKey: record.feedSourceKey,
        feedSourceGroup: `${record.feedSourceGroup}s`,
        feedLanguage: selectedLanguage,
        feedDomain: record.feedDomain,
        feedName: record.feedName,
        feedCurrencyCode: selectedCurrency,
        feedCountryCode: selectedCountry,
        feedIsAdult: record.feedIsAdult,
      });
      console.log(selectedCountry, selectedCurrency, selectedLanguage, selectedGroup);
    }
  }, [record, countries, currencies, languages, sources, reset]);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    setImage({
      currentFile: file,
      previewImage: URL.createObjectURL(file),
    });
  };

  const handleImageUpload = async () => {
    if (image.currentFile && uuid) {
      setIsUploadingImage(true);
      const imageData = new FormData();
      imageData.append('file', image.currentFile);
      try {
        await makeCall(`feeds/${uuid}/logo`, 'POST', imageData);
        setIsUploadingImage(false);
        handleRefresh('Feed Logo uploaded successfully!', 'success');
      } catch (error) {
        console.log(error);
        handleRefresh('Unable to upload logo, please try again!', 'error');
        setIsUploadingImage(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={resetForm}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="form-dialog-title">Feed editor</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Group"
                      variant="outlined"
                      fullWidth
                      error={errors.feedSourceGroup ? true : false}
                      helperText={errors.feedSourceGroup ? 'Group is required!' : null}
                      onChange={(e) => {
                        handleGroupChange(e);
                        field.onChange(e);
                      }}
                    >
                      <MenuItem value="networks">Network</MenuItem>
                      <MenuItem value="templates">Template</MenuItem>
                      <MenuItem value="customs">Custom</MenuItem>
                    </TextField>
                  )}
                  name="feedSourceGroup"
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="feedSourceKey"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label="Source"
                      variant="outlined"
                      fullWidth
                      error={errors.feedSourceKey ? true : false}
                      helperText={errors.feedSourceKey ? 'Source is required!' : null}
                      onChange={(e) => {
                        handleSourceChange(e);
                        field.onChange(e);
                      }}
                    >
                      {groupOptions.map((row) => (
                        <MenuItem value={row.sourceKey} key={row.sourceKey}>
                          {row.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Paper variant="outlined" style={{ padding: '20px', height: '200px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="h6" color="primary">
                      Feed logo
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <label htmlFor="btn-upload" style={{ marginRight: '5%' }}>
                      <input
                        id="btn-upload"
                        name="btn-upload"
                        style={{ display: 'none' }}
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleImageSelect}
                        disabled={!uuid}
                      />
                      <Button disabled={!uuid} className="btn-choose" variant="outlined" component="span">
                        Choose Image
                      </Button>
                    </label>
                    <Button
                      disabled={!uuid || isUploadingImage || !image.previewImage}
                      type="button"
                      color="primary"
                      variant="contained"
                      onClick={handleImageUpload}
                      // fullWidth
                    >
                      {isUploadingImage ? 'Uploading...' : 'Upload'}
                    </Button>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: '5px' }} />
                {image.previewImage && (
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      margin: '25px auto',
                    }}
                  >
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={image.previewImage} alt="feed logo" />
                  </div>
                )}
                {!image.previewImage && record?.feedLogoUrl && (
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      margin: '25px auto',
                    }}
                  >
                    <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={record?.feedLogoUrl} alt="feed logo" />
                  </div>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} style={{ margin: '10px 0' }}>
              <Divider />
            </Grid>
            {isSourceSelected && ['partnerads', 'tradedoubler', 'tradetracker'].includes(sourceKey) && (
              <>
                <Grid item xs={10}>
                  <TextField
                    margin="dense"
                    label="Feed URL"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingTop: '22px' }}>
                  <Button disabled={!url} onClick={splitValues} fullWidth color="primary" variant="contained">
                    Get Values
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 0' }}>
                  <Divider />
                </Grid>
              </>
            )}
            {isSourceSelected &&
              sourceFields.map((row: any) => (
                <Grid item xs={6} key={row.key}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={Object.keys(errors).includes(row.key) ? true : false}
                        helperText={
                          Object.keys(errors).includes(row.key) ? `${row.name} is required!` : row.description
                        }
                        margin="dense"
                        label={row.name}
                        fullWidth
                      />
                    )}
                    name={row.key}
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
              ))}
            {isSourceSelected && (
              <>
                <Grid item xs={6}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors.feedName ? true : false}
                        helperText={errors.feedName ? 'Feed name is required!' : null}
                        margin="dense"
                        label="Name"
                        fullWidth
                      />
                    )}
                    name="feedName"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors.feedDomain ? true : false}
                        helperText={errors.feedDomain ? 'Feed domain is required!' : null}
                        margin="dense"
                        label="Domain"
                        fullWidth
                      />
                    )}
                    name="feedDomain"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="feedLanguage"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="language-select"
                        options={languages}
                        onChange={(_, data) => field.onChange(data)}
                        getOptionLabel={(option) => option}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            error={errors.feedLanguage ? true : false}
                            helperText={errors.feedLanguage ? 'Language is required!' : null}
                            {...params}
                            label="Language"
                            margin="dense"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="feedCurrencyCode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="currency-select"
                        options={currencies}
                        onChange={(_, data) => field.onChange(data)}
                        getOptionLabel={(option) => option.currencyCode}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            error={errors.feedCurrencyCode ? true : false}
                            helperText={errors.feedCurrencyCode ? 'Currency is required!' : null}
                            {...params}
                            label="Currency"
                            margin="dense"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="feedCountryCode"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        id="country-select"
                        options={countries}
                        onChange={(_, data) => field.onChange(data)}
                        getOptionLabel={(option) => option.countryName}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            error={errors.feedCountryCode ? true : false}
                            helperText={errors.feedCountryCode ? 'Country is required!' : null}
                            {...params}
                            label="Country"
                            margin="dense"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Controller
                    name="feedIsAdult"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={field.value} color="primary" />}
                        label="Adult Feed?"
                      />
                    )}
                  />
                </Grid>
                {uuid && (
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Switch onChange={(e) => setIsActive(e.target.checked)} checked={isActive} color="primary" />
                      }
                      label="Feed status?"
                    />
                  </Grid>
                )}
              </>
            )}
            {uuid && (
              <>
                <Grid item xs={12} style={{ margin: '10px 0' }}>
                  <Divider />
                </Grid>
                <Grid item xs={5}>
                  <Paper variant="outlined" style={{ padding: '20px', height: 'auto' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Identification numbers
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <List>
                            <ListItem><ListItemText primary={`EAN : ${record?.feedHasEan ? 'Yes' : 'No'}`}/></ListItem>
                            <ListItem><ListItemText primary={`GTIN : ${record?.feedHasGtin ? 'Yes' : 'No'}`}/></ListItem>
                            <ListItem><ListItemText primary={`IBSN : ${record?.feedHasIbsn ? 'Yes' : 'No'}`}/></ListItem>
                            <ListItem><ListItemText primary={`MPN : ${record?.feedHasMpn ? 'Yes' : 'No'}`}/></ListItem>
                            <ListItem><ListItemText primary={`UPS : ${record?.feedHasUps ? 'Yes' : 'No'}`}/></ListItem>
                          </List>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={7}>
                <Paper variant="outlined" style={{ padding: '20px', height: 'auto' }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                          Example products
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          {/* <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(record?.feedProductExamples)}</pre> */}
                          <JsonEditor placeholder={record?.feedProductExamples} height="500px" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
