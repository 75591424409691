import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { v2Endpoints } from './v2Endpoints';

const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const getToken = () => {
  const token = localStorage.getItem('productFeedAuthKey');
  const { exp } = jwtDecode(token);
  const expirationTime = exp * 1000 - 10000;
  if (Date.now() >= expirationTime) {
    localStorage.removeItem('productFeedAuthKey');
    window.location.assign('/');
  }
  return token;
};

export const makeCall = async (destination, method, data, responseType = 'json') => {
  try {
    const root = v2Endpoints.includes(destination.split('/')[0])
      ? `${ROOT_URL.substring(0, ROOT_URL.length - 3)}v2/`
      : ROOT_URL;
    if (method === 'GET' && data !== null && data !== undefined) {
      const params = Object.keys(data)
        .map(function (key) {
          return key + '=' + data[key];
        })
        .join('&');
      destination += `?${params}`;
    }

    const request = await axios({
      method,
      url: `${root}${destination}`,
      headers: { Authorization: `Bearer ${getToken()}` },
      ...(method !== 'GET' && { data: data }),
      responseType,
    });
    return request;
  } catch (error) {
    const status = error?.response?.status ?? 0;
    if (status === 401) {
      localStorage.removeItem('productFeedAuthKey');
      window.location.assign('/');
    }
    return error?.response;
  }
};
