import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  Drawer,
  List,
  Button,
} from '@material-ui/core'
import { PowerSettingsNew } from '@material-ui/icons'
import { useLocation, useHistory } from 'react-router-dom'
import { AppBarColor, SideBarColor } from '../../elements/colors'
import { links } from './NavLinks'
import { Menu } from './Menu'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: '#fff',
    backgroundColor: AppBarColor,
  },
  grow: {
    flexGrow: 1,
    paddingBottom: '60px',
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tabIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.20) !important',
  },
  tabRoot: {
    minHeight: 64,
    fontSize: 12,
    fontWeight: 600,
  },
  logo: {
    paddingTop: 0,
    width: 390,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    background: SideBarColor,
    width: drawerWidth,
    top: '62px',
    paddingTop: '35px',
  },
  drawerContainer: {
    overflow: 'auto',
  },
}))

export default function Navbar() {
  const classes = useStyles()
  const location = String(useLocation().pathname).replace('/', '')
  const history = useHistory()
  const [currentTabIndex, setCurrentTabIndex] = React.useState<string | number>(
    () => {
      if (location) {
        return location
      }
      return 'dashboard'
    }
  )

  const handleListItemClick = (e: any) => {
    setCurrentTabIndex(e.id)
    history.push(e.url)
  }

  return (
    <>
      <div className={classes.grow}>
        <AppBar position="fixed" className={classes.appBar} variant="outlined">
          <Toolbar style={{ minHeight: '60px' }}>
            <Grid
              container
              justify="space-between"
              alignContent="center"
              direction="row"
            >
              <Grid item className={classes.logo}>
                <Typography variant="h6">
                  Product feed manager
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={(e: any) => {
                    e.preventDefault()
                    window.localStorage.removeItem('productFeedAuthKey')
                    window.location.assign('/login/')
                  }}
                  variant="text"
                  style={{
                    color: '#fff',
                    textTransform: 'none',
                    marginRight: '20px',
                  }}
                  startIcon={<PowerSettingsNew />}
                >
                  <Typography
                    style={{
                      color: '#fff',
                      marginLeft: '10px',
                    }}
                    variant="subtitle2"
                  >
                    <span style={{ fontWeight: 400, fontSize: '16px' }}>
                      Logout
                    </span>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <List style={{ padding: '0 5px' }} data-testid="navigation-list">
            {links.map((link) => (
              <Menu
                key={link.id}
                item={link}
                currentTabIndex={currentTabIndex}
                handleListItemClick={handleListItemClick}
              />
            ))}
          </List>
        </div>
      </Drawer>
    </>
  )
}
