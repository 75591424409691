import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeCall } from 'utils/util';
import { CreateFormProps as FormType, CreateProps as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({open, handleClose, handleRefresh}: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormType>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };

  const onSubmit = async (data: FormType) => {
    const payload = {
      ...data,
    };
    await makeCall('admins', 'POST', payload);
    resetForm();
    handleRefresh();
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={resetForm}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Admin</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.adminEmail ? true : false}
                    helperText={errors.adminEmail ? 'Feed name is required!' : null}
                    autoFocus
                    margin="dense"
                    label="Admin E-mail"
                    fullWidth
                  />
                )}
                name="adminEmail"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.adminPassword ? true : false}
                    helperText={errors.adminPassword ? 'Admin password is required!' : null}
                    margin="dense"
                    label="Admin password"
                    fullWidth
                  />
                )}
                name="adminPassword"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
