import { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Data as APIKeysType } from 'screens/ApiKeys/types';
import Alert from '@material-ui/lab/Alert';

type PropsType = {
  open: boolean;
  handleClose: () => void;
  apiKeys: APIKeysType[];
  feedUuid: string;
};

export default function ApiKeyModal({ open, handleClose, apiKeys, feedUuid }: PropsType) {
  const [apiKey, setApiKey] = useState('');
  const [link, setLink] = useState('');

  const resetForm = () => {
    handleClose();
    setLink('');
    setApiKey('');
  };

  useEffect(() => {
    if (apiKey) {
      setLink(`https://heylinkapi.com/api/productfeeds/v1/google/${feedUuid}?apiKey=${apiKey}`);
    }
  }, [apiKey, feedUuid]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link);
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={resetForm}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">Generate download link</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              select
              label="API key"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setApiKey(e.target.value);
              }}
              value={apiKey}
            >
              {apiKeys.map((row) => (
                <MenuItem value={row.apiKeyUuid} key={row.apiKeyUuid}>
                  {row.apiKeyName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {link && (
            <Grid item xs={12}>
              <Alert
                action={
                  <Button color="inherit" size="small" onClick={() => handleCopyClick()}>
                    Copy
                  </Button>
                }
              >
                {link}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
