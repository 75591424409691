import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeCall } from 'utils/util';
import { UpdateModalProps as PropsType, UpdateFormProps as FormType } from './types';

export default function Update({ open, handleClose, handleRefresh, record }: PropsType) {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormType>();

  useEffect(() => {
    if (record) {
      reset({
        apiKeyName: record.apiKeyName,
        apiKeyActiveStatus: record.apiKeyActiveStatus,
      });
    }
  }, [record, reset]);

  const onSubmit = async (data: FormType) => {
    const payload = {
      ...data,
    };
    const response = await makeCall(`apikeys/${record?.apiKeyUuid}`, 'PUT', payload);
    if (response?.data.code === 200) {
      handleRefresh('API key updated successfully!', 'success');
      close();
    } else {
      handleRefresh(response?.data.message, 'error');
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(record?.apiKeyUuid ?? '');
    setIsCopied(true);
  };

  const close = () => {
    setIsCopied(false);
    handleClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        disableBackdropClick={true}
        onClose={close}
        onEscapeKeyDown={close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update API key</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ID: {record?.apiKeyUuid}{' '}
              <Button disabled={isCopied} color="primary" onClick={handleCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={errors.apiKeyName ? true : false}
                      helperText={errors.apiKeyName ? 'Key name is required!' : null}
                      autoFocus
                      margin="dense"
                      label="API key name"
                      fullWidth
                    />
                  )}
                  name="apiKeyName"
                  control={control}
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="apiKeyActiveStatus"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Switch {...field} defaultChecked={!!record?.apiKeyActiveStatus} color="primary" />}
                      label="Status?"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" color="primary">
              {isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
