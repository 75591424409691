import { lazy } from 'react';
import Countries from './screens/Countries';
import Currencies from './screens/Currencies';
import Admins from './screens/Admins';
import Feeds from './screens/Feeds';
import APIKeys from './screens/ApiKeys';
const FourOFour = lazy(() => import('./screens/FourOFour'));

const routes = [
  {
    title: 'Feeds',
    path: '/',
    exact: true,
    strict: true,
    component: Feeds,
  },
  {
    title: 'Feeds',
    path: '/apikeys',
    exact: true,
    strict: true,
    component: APIKeys,
  },
  {
    title: 'Countries',
    path: '/countries',
    exact: true,
    strict: true,
    component: Countries,
  },
  {
    title: 'Currencies',
    path: '/currencies',
    exact: true,
    strict: true,
    component: Currencies,
  },
  {
    title: 'Admins',
    path: '/admins',
    exact: true,
    strict: true,
    component: Admins,
  },
  {
    title: 'FourOFour',
    component: FourOFour,
  },
];

export default routes;
