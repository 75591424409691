import { useState, useEffect } from 'react';
import { makeCall } from 'utils/util';
import { Data, Record } from './types';
import { Grid, Button } from '@material-ui/core';
import { columns } from './initialData';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Create from './Create';
import { containerStyles, tableContainerStyles as useStyles } from 'elements/container';
import { ModalType } from 'types/global';
import Snackbar from 'components/Snackbar';
import { Color } from '@material-ui/lab/Alert';
import Update from './Update';

export default function APIKeys() {
  const containerClasses = containerStyles();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [record, setRecord] = useState<Record>(null);
  const [toastSeverity, setToastSeverity] = useState<Color>('success');

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('apikeys', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    };
    if (shouldRefresh) {
      getData();
    }
  }, [shouldRefresh]);

  const refresh = (message: string, severity: Color) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
    if (severity === 'success') {
      setIsLoading(true);
      setShouldRefresh(true);
    }
  };

  const getRecord = async (clickedRow: Data) => {
    const response = await makeCall(`apikeys/${clickedRow.apiKeyUuid}`, 'GET');
    setRecord(response?.data.data);
    setModalType('update');
  };

  const handleClose = () => {
    setModalType(null);
  };

  return (
    <>
      <Create open={modalType === 'create'} handleClose={handleClose} handleRefresh={refresh} />
      <Update open={modalType === 'update'} handleClose={handleClose} handleRefresh={refresh} record={record} />
      <Snackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} severity={toastSeverity} />
      <div className={containerClasses.container}>
        <Grid container className={classes.root}>
          <Grid item xs={12} style={{ textAlign: 'right', margin: '20px 0' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalType('create')}
              style={{ marginRight: '20px' }}
            >
              Create API key
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title="API keys"
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarWrapper}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                pageSize: 10,
                actionsColumnIndex: 10,
                actionsCellStyle: { color: '#999' },
              }}
              actions={[
                {
                  icon: 'edit',
                  tooltip: 'Update API key',
                  isFreeAction: false,
                  onClick: (event, rowData: any) => getRecord(rowData),
                },
              ]}
              isLoading={isLoading}
              columns={columns}
              data={data}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
