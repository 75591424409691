import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { makeCall } from 'utils/util';
import { FormProps as FormType, BaseProps as PropsType } from './types';
import { createDefaultValues as defaultValues } from './initialData';

export default function Create({ open, handleClose, handleRefresh }: PropsType) {
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormType>({ defaultValues });

  const resetForm = () => {
    reset(defaultValues);
    handleClose();
  };
  const onSubmit = async (data: FormType) => {
    const payload = {
      ...data,
    };
    const response = await makeCall('apikeys', 'POST', payload);
    if (response?.data.code === 200) {
      handleRefresh('API key created successfully!', 'success');
      resetForm();
    } else {
      handleRefresh(response?.data.message, 'error');
    }
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      onEscapeKeyDown={resetForm}
      onClose={resetForm}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Create API key</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={errors.apiKeyName ? true : false}
                    helperText={errors.apiKeyName ? 'Key name is required!' : null}
                    autoFocus
                    margin="dense"
                    label="API key name"
                    fullWidth
                  />
                )}
                name="apiKeyName"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm} color="primary">
            Cancel
          </Button>
          <Button disabled={isSubmitting} type="submit" color="primary">
            {isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
