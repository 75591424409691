import { Grid } from '@material-ui/core';
import React from 'react';
import { StatsType } from '../types';
import Card from './Card';

type Props = {
  isLoading: boolean;
  data: StatsType[];
};

export const formatter = new Intl.NumberFormat('da-DK');

export default function CardsContainer({ isLoading, data }: Props) {
  return (
    <Grid container alignItems="center" spacing={3}>
      {data.map((row, idx) => (
        <React.Fragment key={idx}>
          <Card
            title="Products"
            value={formatter.format(row?.feedProductsSum ?? 0)}
            isLoading={isLoading}
          />
          <Card
            title="Feeds"
            value={formatter.format(row?.feedsCount ?? 0)}
            isLoading={isLoading}
          />
          <Card
            title="Active Feeds"
            value={formatter.format(row?.feedIsActiveCount ?? 0)}
            isLoading={isLoading}
          />
          <Card
            title="Inactive Feeds"
            value={formatter.format(row?.feedIsNotActiveCount ?? 0)}
            isLoading={isLoading}
          />
          <Card
            title="Latest import"
            value={row?.feedLatestImportDatetime ?? ''}
            isLoading={isLoading}
          />
          <Card
            title="oldest import"
            value={row?.feedOldestImportDatetime ?? ''}
            isLoading={isLoading}
          />
        </React.Fragment>
      ))}
    </Grid>
  );
}
