import { Column } from '@material-table/core';
import { Data } from './types';
import { green, red } from '@material-ui/core/colors';
import { FormProps } from './types';
import { Avatar, Icon } from '@material-ui/core';

const cellStyle = { fontWeight: 400, fontFamily: 'Roboto', fontSize: '0.8rem' };
const logoStyle = { width: 30, height: 30 };

const formatter = new Intl.NumberFormat('da-DK');

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'feedUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  {
    title: 'Logo',
    field: 'feedLogoUrl',

    render: (row) => {
      return row.feedLogoUrl ? (
        <Avatar src={row.feedLogoUrl} style={logoStyle} />
      ) : (
        <Avatar style={logoStyle}>{row.feedName.slice(0, 1)}</Avatar>
      );
    },
  },
  {
    title: 'Name',
    field: 'feedName',
    cellStyle: { ...cellStyle, width: '38%' },
  },
  { title: 'Domain', field: 'feedDomain', cellStyle },
  { title: 'Currency', field: 'feedCurrencyCode', cellStyle },
  { title: 'Country', field: 'feedCountryCode', cellStyle },
  { title: 'Language', field: 'feedLanguage', cellStyle },
  {
    title: 'Products',
    field: 'feedProductCount',
    render: (row) => <span>{formatter.format(row.feedProductCount)}</span>,
    cellStyle,
  },
  {
    title: 'Categories',
    field: 'feedCategoryCount',
    render: (row) => <span>{formatter.format(row.feedCategoryCount)}</span>,
    cellStyle,
  },
  {
    title: 'Active',
    field: 'adminStatus',
    render: (row) =>
      row.feedIsActive ? (
        <Icon style={{ color: green[400] }}>check_circle</Icon>
      ) : (
        <Icon style={{ color: red[400] }}>remove_circle</Icon>
      ),
  },
  { title: 'Imported on', field: 'feedImportedDatetime', cellStyle },
];

export const createDefaultValues: FormProps = {
  feedImportId2: '',
  feedImportId1: '',
  feedSecret: '',
  feedId: '',
  feedUrl: '',
  feedSourceKey: '',
  feedSourceGroup: '',
  feedLanguage: null,
  feedDomain: '',
  feedName: '',
  feedCurrencyCode: null,
  feedCountryCode: null,
  feedIsAdult: false,
};
