import { Column } from '@material-table/core';
import {Data, CreateFormProps} from './types'

const cellStyle = {fontWeight: 400, fontFamily: 'Roboto', fontSize: '0.8rem'}

export const columns: Array<Column<Data>> = [
  { title: 'ID', field: 'adminUuid', cellStyle: { display: 'none' }, headerStyle: { display: 'none' } },
  { title: 'Admin name', field: 'adminEmail', cellStyle },
];

export const createDefaultValues: CreateFormProps = {
  adminEmail: '',
  adminPassword: '',
};