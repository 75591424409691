import { useState, useEffect } from 'react';
import { makeCall } from 'utils/util';
import { Grid } from '@material-ui/core';
import { columns } from './initialData'; 
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {containerStyles, tableContainerStyles as useStyles} from 'elements/container'
import {Currency} from 'types/global'


export default function Countries() {
  const containerClasses = containerStyles();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Currency[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('currencies', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
    }
    getData();
  }, [])

  return (
    <div className={containerClasses.container}>
        <Grid container className={classes.root}>
        <Grid item xs={12}>
        <MaterialTable
              title="Currencies"
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarWrapper}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                pageSize: 10,
                actionsColumnIndex: 10,
                actionsCellStyle: { color: '#999' },
              }}
              isLoading={isLoading}
              columns={columns}
              data={data}
            />
        </Grid>
      </Grid>
      </div>
  )
}
