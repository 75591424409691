import { useState, useEffect } from 'react';
import { makeCall } from 'utils/util';
import { Data } from './types';
import { Grid, Button } from '@material-ui/core';
import { columns } from './initialData'; 
import MaterialTable, { MTableToolbar } from '@material-table/core';
import Create from './Create';
import {containerStyles, tableContainerStyles as useStyles} from 'elements/container'
import {ModalType} from 'types/global'
import Snackbar from 'components/Snackbar'

export default function Admins() {
  const containerClasses = containerStyles();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
    const getData = async () => {
      const response = await makeCall('admins', 'GET');
      setData(response?.data.data);
      setIsLoading(false);
      setShouldRefresh(false);
    }
    if(shouldRefresh) {
      getData();
    }
  }, [shouldRefresh])

  const refresh = (key: string) => {
    const message = `Admin ${key} successfully!`;
    setToastMessage(message);
    setToastOpen(true);
    setIsLoading(true);
    setShouldRefresh(true);
  }

  const handleClose = () => {
    setModalType(null)
  }

  return (
    <>
      <Create 
        open={modalType === 'create'}
        handleClose={handleClose}
        handleRefresh={() => refresh('created')}
      />
      <Snackbar open={toastOpen} message={toastMessage} onClose={() => setToastOpen(false)} />
      <div className={containerClasses.container}>
        <Grid container className={classes.root}>
          <Grid item xs={12} style={{textAlign: 'right', margin: '20px 0'}}>
            <Button variant="contained" color="primary" onClick={() => setModalType('create')} style={{marginRight: '20px'}}>Create Admin</Button>
          </Grid>
        <Grid item xs={12}>
        <MaterialTable
              title="Admins"
              components={{
                Toolbar: (props) => (
                  <div className={classes.toolbarWrapper}>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                pageSize: 10,
                actionsColumnIndex: 10,
                actionsCellStyle: { color: '#999' },
              }}
              isLoading={isLoading}
              columns={columns}
              data={data}
            />
        </Grid>
      </Grid>
      </div>
    </>
  )
}
